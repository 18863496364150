@import './colors.css';
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;900&family=Roboto:wght@900&display=swap');

/* 
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif; */

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.cont-events{
  position: absolute;
  /* width: 77%; */
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: red;
  font-size: 100px;
  font-weight: 800;
}


