@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;900&family=Roboto:wght@900&display=swap');
@import './colors.css';

body {
    background: var(--color-black);
}

.heading {
    position: relative;
    height: 30vh;
    width: 100%;
}

.heading h1 {
    font-size: 6rem;
    color: var(--color-black);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20vh;
    font-family: "Montserrat";
    font-weight: 700;
}

.heading .bgImage {
    filter: blur(4px);
    width: 100%;
    height: 70vh;
}

.heading img {
    object-fit: cover;
}

.main-contents {
    display: flex;
    color: var(--color-black);
    flex-direction: column;
    margin-top: 60vh;
    align-items: center;
    margin-left: 0%;
}

.event h3 {
    padding-bottom: 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
}

.event p {
    font-size: 1.2rem;
}

.event {
    text-align: justify;
}

.event-image img {
    height: 100%;
    width: 100%;
}

.event-image {
    width: 35vmax;
    height: 20vmax;
    object-fit: cover;
}

.event-cont {
    width: 60%;
    margin-left: 10%;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.event-section {
    width: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 15vmax;
}

.event img {
}

@media (max-width: 950px) {
    .heading {
        position: relative;
        height: 20vh;
        width: 100%;
    }

    .heading .bgImage {
        width: 100%;
        height: 50vh;
    }

    .heading h1 {
        font-size: 4rem;
    }

    .main-content .event {
        padding: 2rem;
    }

    .event-section {
        flex-direction: column;
    }

    .event-cont {
        width: 90%;
        padding-top: 3vmax;
        margin-left: 0;
    }

    .event h3 {
        padding-bottom: 0.6rem;
        margin: 0 auto;
        text-align: center;
        font-size: 2rem;
    }

    .event p {
        font-size: 0.9rem;
    }
}