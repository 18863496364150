@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;900&family=Roboto:wght@900&display=swap');
@import './colors.css';

body {
    background: var(--color-black);
}

.heading {
    position: relative;
    height: 30vh;
    width: 100%;
}

.heading h1 {
    font-size: 6rem;
    color: var(--color-white);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20vh;
    font-family: "Montserrat";
    font-weight: 700;
}

.heading .bgImage {
    filter: blur(4px) brightness(70%);
    width: 100%;
    height: 70vh;
}

.heading img {
    object-fit: cover;
}

.main-content {
    display: flex;
    color: var(--color-white);
    flex-direction: column;
    margin-top: 60vh;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
}

.project h3 {
    padding-bottom: 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
}

.project p {
    font-size: 1.4rem;
    font-weight: 300;
}

.project {
    text-align: justify;
}

.project-image img {
    height: 20vmax;
    width: 20vmax;
}

.project-cont {
    width: 50%;
    margin-left: 10%;
    height: 100%;
}

.project-section {
    width: 90%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15vmax;
}

.project-div {
    display: flex;
    flex-direction: row;
}

@media (max-width: 950px) {
    .project-section {
        width: 100vw;
        flex-direction: column;
    }

    .project-image img {
        height: 35vmax;
        width: 35vmax;
        margin: 0 auto;
    }

    .project-image {
        width: 35vmax;
    }

    .project-cont {
        margin-left: 0%;
    }

    .heading h1 {
        font-size: 3.2rem;
    }

    .main-content {
        flex-direction: column;
    }

    .project-cont {
        width: 90%;
        padding: 2rem;
    }

    .project h3 {
        padding-bottom: 1rem;
        font-size: 2rem;
    }

    .project p {
        font-size: 1rem;
    }
}