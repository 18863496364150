@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;900&family=Roboto:wght@900&display=swap');

/* 
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif; */

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif; */

/*==========================Footer=========================*/

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, var(--color-black), var(--color-dark-blue));
  color: var(--color-white);
}

.footerdivs {
  display: flex;
  flex-direction: row;
  width: 80vw;  
  font-size: 14px;
}

.footerdiv1 {
  display: flex;
  flex-direction: row;
}

.footerdiv1-content1, .footerdiv1-content2, .footerdiv2 {
  border-bottom: 2px solid var(--color-box-shadow);
  padding: 12px 0px;
}

.footerimg i {
  margin: 0px 8px;
  font-size: x-large;
  color: var(--color-special-box-shadow); 
}

.bif:hover {
  color: #316FF6;
}

.bii:hover {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.bil:hover {
  color: #0077b5;
}

.bit:hover {
  color: #1DA1F2;
}

.footer-content-img {
  display: flex;
  box-shadow: 2px 2px 4px var(--color-box-shadow);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  width: 300px;
  margin: -45px auto;
  background-color: var(--color-black);
}

.footer-content-img img {
  margin: 0px 20px;
  filter: invert(100%) brightness(100%) contrast(100%);
  background-color: var(--color-white);
  display: inline-block;  
}

.footerdiv2 {
  display: flex;
  flex-direction: column;
}

.copyright p {
  font-size:small;
  margin-bottom: 0;
  color: var(--color-special-box-shadow);
}

.copyright {
  padding: 3% 0%;
}

@media(max-width: 600px) {
  .footerdivs {
    flex-direction: column;
  }
  .copyright p{
    font-size: xx-small;
  }
}

@media(max-width: 500px) {
  .footerdiv1 {
    flex-direction: column;
  }
}

/* ========================== Header(Navbar) ================== */
.navbars {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0) !important;
  --webkit-box-shadow: 0 8px 6px -6px background-color !important;
}

.navbar-scrolled {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;  
  background: var(--color-black) !important;
  line-height: 26px;
  text-transform: uppercase; 
  box-shadow: 0 8px 6px -6px var(--color-black);
  --webkit-box-shadow: 0 8px 6px -6px background-color !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.container-fluid {
  position: relative;
  height: 4vmax;
}

.overlay-div {
  position: absolute;
  top: 0px;
  background-color: var(--color-white);
  height: 100%;
}

.overlay-div img {
  height: 100%;
  width: 100%;
}

.nav-link {
  font-weight: 300 !important;
  color: var(--color-white);
}

.nav-link:hover {
  color: var(--color-white);
}

.active {
  border-bottom: 2px solid var(--color-white);
  color: var(--color-white) !important;
}

@media (max-width: 991px) {
  .container-fluid {
    --bs-gutter-x: 0;
    height: 6vmax;
  }

  .navbar-nav {
    background-color: var(--color-black);
    width: 100vw;
  }

  .overlay-div {
    height: auto;
  }

  .navbar {
    background-color: var(--color-black) !important;
  }

  .overlay-div {
    top: 0;
    left: 0;
    height: 6vmax;
  }
}

/* ==============================Page not found====================== */
.pnf {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: var(--color-black);
  border: 1px solid var(--color-black);
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}

.pnf-btn:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}