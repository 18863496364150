.intro-pagess{
    background-color: white;
    /* padding: 0 0; 
    margin: 0 0; */
    /* box-sizing: border-box; */
    height: 100vh;
    width: 100vw;   
}

  .containers{
    color: white;
     
    background-color: black;
     padding: 0 0;
    margin: 0 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-page{
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #2c5364, #203a43, #0f2027);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#svg{
     /* filter: invert(100%); */
     /* height: 20vmax; */
     width: 20vmax;    
     stroke: #171313; 
     /* fill-opacity: 0; */
     stroke-width: 30px;
}