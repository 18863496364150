.timeline {
    margin-top: 200px;
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
}

.achievement-body {
    padding: 100px 0px;
    width: 100%;
    background: url("https://i.postimg.cc/65C7N3nN/OC3WJW0.jpg");
    background-color: var(--color-dark-blue);
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

.achivement-container {
    padding: 10px 50px;
    position: relative;
    width: 50%;
    animation: movedown 1s linear forwards;
    opacity: 0;
    z-index: 10;
}

.achivement-container img {
    position: absolute;
    width: 40px;
    border-radius: 50%;
    right: -20px;
    top: 32px;
    background-color: var(--color-white);
}

.right-container img {
    left: -20px;
}

@keyframes movedown {
    0% {
        opacity: 1;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.achivement-container:nth-child(1) {
    animation-delay: 0s;
}
.achivement-container:nth-child(2) {
    animation-delay: 1s;
}
.achivement-container:nth-child(3) {
    animation-delay: 2s;
}
.achivement-container:nth-child(4) {
    animation-delay: 3s;
}
.achivement-container:nth-child(5) {
    animation-delay: 4s;
}
.achivement-container:nth-child(6) {
    animation-delay: 5s;
}

.text-box {
    padding: 20px 30px;
    background: var(--color-white);
    position: relative;
    border-radius: 6px;
    font-size: 15px;
}

.text-box p {
    text-align: justify;
}

.left-container {
    left: 0;
}

.right-container {
    left: 50%;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background: var(--color-white);
    top: 0;
    left: 50%;
    margin-left: -3px;
    z-index: 1;
    animation: moveline 6s linear forwards;
}

.left-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid var(--color-white);
    right: -15px;
}

.right-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid var(--color-white);
    left: -15px;
}

@keyframes moveline {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}

.text-box h2 {
    font-weight: 600;
}

.text-box small {
    display: inline-block;
    margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
    .timeline {
        margin: 50px auto;
    }
    .timeline::after {
        left: 31px;
    }
    .achivement-container {
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }
    .text-box {
        font-size: 13px;
    }
    .text-box small {
        margin-bottom: 10px;
    }
    .right-container {
        left: 0;
    }
    .left-container img, .right-container img {
        left: 10px;
    }
    .left-container-arrow, .right-container-arrow {
        border-right: 15px solid var(--color-white);
        border-left: 0;
        left: -15px;
    }
}